
export enum Role {
    Sportsmen = 'Sportsmen',
    Scout = 'Scout',
    ADMIN = 'ADMIN',
    USER = 'USER',
}
export interface User {
    id: string;
    email: string;
    name: string;
    role: Role;
    profileCover: string;
    birthDate: number;
    weight: number;
    height: number;
    currentTeam: string;
    leagues: string;
    supercup: string;
    description: string;
}

export enum Type {
    Football = 'Football',
    Basketball = 'Basketball',
    Rugby = 'Rugby',
    Other = 'Other',
    Tennis = 'Tennis',
}

export const TypeMapper: any = {
    1: Type.Football,
    2: Type.Basketball,
    3: Type.Rugby,
    4: Type.Tennis,
    5: Type.Other,

}
