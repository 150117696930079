import React, {useState} from "react";
import "./style.css";
import { Buttons } from "../../components/Buttons";
import { IconEye } from "../../components/IconEye";
import AuthService from "../../services/auth-service";
import { useNavigate } from "react-router-dom";
import {Error} from "../../components/Error";



export const SignUp = (): JSX.Element => {
  const [mail , setMail] = useState('');
  const [password , setPassword] = useState('');
  const [badSignIn , setBadSignIn] = useState(false);
  const [isSignIn , setIsSignIn] = useState(true);
  const [role , setRole] = useState(0);
  const [registerStep , setRegisterStep] = useState(1);
  const [newMail , setNewMail] = useState('');
  const [newPassword , setNewPassword] = useState('');
  const [name , setName] = useState('');
  const [confirmPassword , setConfirmPassword] = useState('');
  const [badRegister , setBadRegister] = useState(false);
  const [passwordsNotMatch , setPasswordsNotMatch] = useState(false);
  const [emailExists , setEmailExists] = useState(false);
  const [badEmail , setBadEmail] = useState(false);
  const [success , setSuccess] = useState(false);

    const navigate = useNavigate();
  const user = AuthService.getCurrentUser();
    if(user && !success){
        navigate('/home');
    }
    if(isSignIn && registerStep !== 1){
        setRegisterStep(1);
    }

  const handleMailChange = (item:any) => {
    setMail(item.target.value);
  };
  const handlePasswordChange = (item:any) => {
    setPassword(item.target.value);
  };
    const handleNewMailChange = (item:any) => {
        setNewMail(item.target.value);
    };
    const handleNameChange = (item:any) => {
        setName(item.target.value);
    };
    const handleNewPasswordChange = (item:any) => {
        setNewPassword(item.target.value);
    };
    const handlePasswordConfirmChange = (item:any) => {
        setConfirmPassword(item.target.value);
        if(newPassword === item.target.value) {
            setPasswordsNotMatch(false);
        }else{
            item.target.value.length > 0 && setPasswordsNotMatch(true);
        }
    };


  const handleRoleChange = (newRole:number): undefined => {
      if(role === newRole){
          setRole(0);
      }else{
          setRole(newRole);
      }
      return undefined;
  }

    const handleRegisterStepChange = (step:number): undefined => {
      if(role !== 0) setRegisterStep(step);
        return undefined;
    }

  const handleSignIn = (item:any) => {
      AuthService.login(mail, password).then(
          () => {
            navigate('/home');
          },
          error => {
            setBadSignIn(true)
          }
      );
  };
    const handleRegister = () => {
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const validEmail: boolean = expression.test(newMail);
        if(!validEmail){
            setBadEmail(true);
            return;
        }else{
            setBadEmail(false);
        }
        if(newPassword !== confirmPassword)return;
        setSuccess(true)
        AuthService.register(newMail, newPassword, name , role).then(
            () => {

                    setRegisterStep(3);
            },
            error => {
                setSuccess(false);
                if(error.response.status === 400) {
                    setEmailExists(true)
                }
            }
        );
    };
  return (
    <div className="sign-up">
      <div className="div">
        <div className="overlap-group">
          <h1 className="one-space-where-you">
            One space where
            <br />
            you can find, explore and communicate
          </h1>
          <div className="text-wrapper">PROSPECT X</div>
          <img className="frame" alt="Frame" src="/frame.svg" />
        </div>
        { isSignIn && <div>
          <div className="frame-2">
            <div className="create-an-account">Sign In</div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper-2">Email</div>
          </div>
          <div className="input-text-wrapper">
            <div className="input-text">
              <input className="text-wrapper-3" onChange={handleMailChange} placeholder={'Enter your email'}></input>
              <div className="frame-3" />
            </div>
          </div>
          <div className="frame-4">
            <div className="text-wrapper-2">Password</div>
          </div>
          <div className="input-text-2">
            <div className="content">
              <div className="content-2">
                <input className="filled" type="password" onChange={handlePasswordChange} placeholder={'Enter your password'}></input>
              </div>
            </div>
            <div className="icon-eye-wrapper">
              <IconEye className="icon-eye-instance" iconEye="/icon-eye.svg" />
            </div>
          </div>
          <div className="frame-5">
            <Buttons className="buttons-instance" property1="primary" text="Sign In" iconGoogleOriginalIconGoogleOriginal="/icon-google-original-2.svg"
                     icon={false} onClick={handleSignIn}/>
            <Buttons
                className="design-component-instance-node"
                iconGoogleOriginalIconGoogleOriginal="/icon-google-original-2.svg"
                property1="secondary"
                text="Continue with Google"
                icon={true}
                onClick={() => console.log(1)}
            />
            <div
                onClick={() => setIsSignIn(!isSignIn)}
                className="frame-6">
              <p className="already-have-an">Don't Have An Account ?</p>
              <div className="log-in">Sign Up</div>
            </div>

          </div>
          {badSignIn && <div className="group">
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <Error style="outlined"/>
            <p className="helper-text-error alert-text">Invalid Credentials</p>
          </div>}
        </div>}
          {!isSignIn && (registerStep === 1) && <div className={'selector-class top-marg'}>
              <h1 className={'selector-text'}>Create account</h1>
              <button onClick={() => handleRoleChange(1)} className={`sporstman-button ${role === 1 && 'chosen-button'}`}> sportsman</button>
              <button  onClick={() => handleRoleChange(2)} className={`trainer-button ${role === 2 && 'chosen-button'}`}> agent/scout</button>
              <button  onClick={() => handleRegisterStepChange(2)} className={`continue-button ${role !== 0 && 'is-smth-selected'}`}> continue</button>
          </div>
          }
          {!isSignIn && (registerStep === 2) && <div className={'selector-class'}>
              <h1 className={'selector-text'}>Create {role === 1 ? 'Sporstman' : "Agent/Scout"} Account</h1>
              <h4 className={'selector-text'}>Your Name</h4>
              <input className={'input-text register-input'} placeholder={'Input your name'} onChange={handleNameChange}></input>
              {/* email input*/}
                <h4 className={'selector-text'}>Your Email</h4>
                <input className={'input-text register-input'} placeholder={'Input email address'} onChange={handleNewMailChange} ></input>
              { emailExists && <p className="selector-text alert-text">* The email you entered already exists.</p>}
              { badEmail && <p className="selector-text alert-text">* Please enter correct mail format.</p>}
                {/* password input*/}
                <h4 className={'selector-text'}>Your Password</h4>
                <input className={'input-text register-input'} placeholder={'Input your password'} onChange={handleNewPasswordChange} type={'password'}></input>
                {/* password input*/}
                <div className={'mbot-30'}><h4 className={'selector-text'}>Confirm Password</h4>
                    <input className={'input-text register-input'} placeholder={'Confirm Password'} onChange={handlePasswordConfirmChange} type={'password'}></input>
                    {passwordsNotMatch &&  <p className="selector-text alert-text">* The passwords you entered do not match.</p>}</div>

              <button onClick={() => {handleRegister()}} className={`continue-button ${role !== 0 && 'is-smth-selected'}`}>Create</button>
                <div
                    onClick={() => setIsSignIn(!isSignIn)}
                    className="frame-6">
                    <p className="already-have-an">Already Have An Account ?</p>
                    <div className="log-in">Sign In</div>
                </div>
          </div>
          }
          {
              !isSignIn && registerStep === 3 && <div className={'selector-class'}>
                  <img className="success-mark" alt="Frame" src="/success-mark.svg" />
                  <h2 className={'selector-text success-text'}>Your Account successfully created.</h2>
                  <button onClick={() => {navigate('/home')}} className={`continue-button ${role !== 0 && 'is-smth-selected'}`}>Continue</button>
                </div>
          }
      </div>
    </div>
  );
};
