import React from "react";
import "./style.css";
import PropTypes from "prop-types";
import AuthService from "../../services/auth-service";
import {Link} from "react-router-dom";
import {Role} from "../../services/interfaces/user.interace";


interface Props {
    show: boolean,
    userName:string,
    userId: string,
    role: Role
}
export const ProfileBox = ({show, userName , userId, role}: Props): JSX.Element => {
    return (
        <div className={`profile-box ${!show && 'profile-box-gone' }`}>
            <div className="search-hover-wrapper">
                <div className="search-hover">
                    <div className="overlap-group-box">
                        <p className="my-videos-likes">
              <span className="text-wrapper-box">
{ role=== Role.Sportsmen && <Link to={`/profile/${userId}`} className={'nnn'} style={{textDecoration: 'none'}}>
    <div className={'hvr-box'}>My Videos</div>
    <br/></Link>
}                  <Link to={`/profile/${userId}?videoType=liked`} className={'nnn'} style={{textDecoration: 'none'}} > <div className={'hvr-box'}>Likes</div> <br /></Link>
                 <Link to={`/profile/${userId}?videoType=saved`} className={'nnn'} style={{textDecoration: 'none'}} > <div className={'hvr-box'}>Saved</div> <br /></Link>
                 <div className={'hvr-box'}>Help</div> <br />
              </span>
                            <span className="span hvr-box" onClick={() => AuthService.logout()}>
                <br />
                Log out
                <br />
              </span>
                        </p>
                        <Link to={`/profile/${userId}`} className="pp" style={{textDecoration: 'none'}} >View Profile / Edit Profile</Link>
                        <div className="divv">{userName}</div>
                        <img
                            className="line"
                            alt="Line"
                            src="https://generation-sessions.s3.amazonaws.com/7c05197f62ca2b22f8035c4895e4f3e5/img/line-33.svg"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

ProfileBox.propTypes = {
    show: PropTypes.bool,
    userName: PropTypes.string,
    userId: PropTypes.string,
    role: PropTypes.string
};
