import axios from "axios";
import {TypeMapper, User} from "./interfaces/user.interace";

const API_URL = "https://hammerhead-app-8h2v7.ondigitalocean.app/users";
const API_URL_CORE = "https://hammerhead-app-8h2v7.ondigitalocean.app";
// const API_URL = "http://localhost:3333/users";
// const API_URL_CORE = "http://localhost:3333";


class AuthService {
    login(email: string, password: string) {
        return axios
            .post(API_URL + "/login", {
                email,
                password
            })
            .then(response => {
                if (response.data.accessToken) {
                    console.log('saving user')
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                console.log(response.data);
                return response.data;
            });
    }

    logout() {
        localStorage.clear()
        window.location.reload();
    }

    register(email: string, password: string, name: string, role: number) {
        return axios.post(API_URL, {
            email,
            password,
            name,
            role: role === 1 ? 'Sportsmen' : 'Scout'
        }).then(response => {
            if (response.data.accessToken) {
                console.log('saving user')
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            console.log(response.data);
            return response.data;
        });
    }

    getCurrentUser() {
        const userStr = localStorage.getItem("user");
        if (userStr) return JSON.parse(userStr);
        return null;
    }

    async getVideos(page: number, limit: number, search: string | undefined , category: number) {
        const token = this.getCurrentUser().accessToken;
        const response = await axios.get(API_URL_CORE + "/upload", {
            params: {
                search,
                page,
                limit,
                type: category ? TypeMapper[category] : undefined
            },
            headers: { Authorization: `Bearer ${token}` }

        });

        return response.data;
    }

    async uploadFileToNestJS(file: File | null, textData: string , func: any) {
        if (!file) return;
        try {
            const token = this.getCurrentUser().accessToken;
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);
            formData.append('post', textData);

            const response = await axios.post(API_URL_CORE + "/upload", formData, {
                headers: { Authorization: `Bearer ${token}`  , "Content-Type": "multipart/form-data"},
                onUploadProgress: (data:any)  =>{ func(Math.round((100 * data.loaded) / data.total))}
            });

            console.log('File uploaded successfully. URL:', response.data);
        } catch (error: any) {
            console.error('Error uploading file:', error.message);
        }
    }

    async getUserInfo(): Promise<User> {
        const token = this.getCurrentUser().accessToken;
        const response = await axios.get(API_URL + "/me", {
            headers: { Authorization: `Bearer ${token}` }
        });
        if(response.data)
        localStorage.setItem("userData", JSON.stringify(response.data));
        return response.data;
    }

    async getOtherUserInfo(id: string): Promise<User> {
        const token = this.getCurrentUser().accessToken;
        const response = await axios.get(API_URL + `/${id}`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }

    async uploadCoverToNestJS(file: File | null) {
        if (!file) return;
        try {
            const token = this.getCurrentUser().accessToken;
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            const response = await axios.post(API_URL_CORE + "/upload/cover", formData, {
                headers: { Authorization: `Bearer ${token}`  , "Content-Type": "multipart/form-data"}
            });

            console.log('File uploaded successfully. URL:', response.data);
        } catch (error: any) {
            console.error('Error uploading file:', error.message);
        }
    }

    async updateUserInfo(userDataEdit: User) {
        const token = this.getCurrentUser().accessToken;
        const response = await axios.patch(API_URL, userDataEdit, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }

    async getVideosDetailed(userId: string) {
        const token = this.getCurrentUser().accessToken;
        const response = await axios.get(API_URL_CORE + "/upload", {
            params: {
                userId,
                page:1,
                limit:100,
            },
            headers: { Authorization: `Bearer ${token}` }

        });

        return response.data;
    }

    async getSavedVideos(userId: string) {
        const token = this.getCurrentUser().accessToken;
        const response = await axios.get(API_URL_CORE + "/upload/saved", {
            params: {
                page:1,
                limit:100,
            },
            headers: { Authorization: `Bearer ${token}` }

        });

        return response.data;
    }



    async getCurrentUserData(): Promise<User>{
        const userStr = localStorage.getItem("userData");
        if (userStr) return JSON.parse(userStr);
        const user = await this.getUserInfo();
        return user;
    }

    async likeVideo(id: number) {
        const token = this.getCurrentUser().accessToken;
        const response = await axios.post(API_URL_CORE + "/upload/like", {uploadId: id}, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;

    }

    async saveVideo(id: number) {
        const token = this.getCurrentUser().accessToken;
        const response = await axios.post(API_URL_CORE + "/upload/save", {uploadId: id}, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;

    }

    async getLikedVideos(id: string) {
        const token = this.getCurrentUser().accessToken;
        const response = await axios.get(API_URL_CORE + "/upload/liked", {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
    }
}

export default new AuthService();