import React, {useEffect, useState} from "react";
import "./style.css";
import {Card} from "../../components/Card";
import AuthService from "../../services/auth-service";
import Upload from "../../components/Upload/Upload";
import {Navigate} from "react-router-dom";
import {ProfileBox} from "../../components/ProfileBox";
import {Role, User} from "../../services/interfaces/user.interace";

export const HomePage = (): JSX.Element => {

    const [data, setData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showProfileBox, setShowProfileBox] = useState(false);
    const [search , setSearch] = useState('');
    const user = AuthService.getCurrentUser();
    const [categoryChosen , setCategoryChosen] = useState(1);
    const [userData , setUserData] = useState({} as User);
    useEffect(() => {
        if(user){
            AuthService.getUserInfo().then((res) => {
                console.log(res);
                console.log('aq movedi1232')
                setUserData(res);
            });
            dataFetch(1 , 200 , undefined , categoryChosen);
        }
    }, []);
    if(!user){
        return (<Navigate to={'/'}/>)
    }
    console.log('aq movedi');

    const handleSearchChange = (item:any) => {
        setSearch(item.target.value);
        if(item.target.value !== ''){
            dataFetch(1 , 200 ,item.target.value , categoryChosen);
        }else {
            dataFetch(1 , 200 , undefined , categoryChosen);
        }

    }

    const likeVideo = async (id: number) => {
        await AuthService.likeVideo(id);
        const newData: any = data.map((item:any) => {
            if(item.id === id){
                item.isLiked = !item.isLiked;
                item.likedCount = item.isLiked ? item.likedCount + 1 : item.likedCount - 1;
            }
            return item;
        })
        setData(newData);

    }

    const saveVideo = async (id: number) => {
        await AuthService.saveVideo(id);
        const newData: any = data.map((item:any) => {
            if(item.id === id){
                item.isSaved = !item.isSaved;
            }
            return item;
        })
        setData(newData);
    }

    const handleCategoryChange = (category:number) => {
        setCategoryChosen(category);
        dataFetch(1 , 20 , search === '' ? undefined : search , category);
    }
    const dataFetch = async (page: number , limit: number , search: string | undefined, category: number) => {
        const data: any = await AuthService.getVideos(page, limit , search,category);
        // set state when the data received
        setData(data);
    };


    return (
        <div className="home-page">
            <div className="overlap-wrapper">
                <div className={'fill-alert'}>Fill the profile information!( jer araa profile xd))</div>
                <div className="overlap">
                    <div className="rectangle" />
                    { showPopup && <Upload onClose={() => setShowPopup(false)}></Upload>}
                    <ul className="main-space">
                        {
                            data.map((videoData: any) => {
                                return (<li key={videoData.id}>
                                        <Card isSaved={videoData.isSaved} onSaveClick={() => saveVideo(videoData.id)} showClick={true} isLiked={videoData.isLiked} item={videoData} text={videoData.title} className={"video-post"} icon={false} iconGoogleOriginalIconGoogleOriginal={""} onClick={() => likeVideo(videoData.id)} property1={"primary"}></Card>
                                    </li>
                                )
                            })
                        }
                    </ul>

                    <div className="side-bar">
                        <div className="overlap-7">
                            <div className="rectangle-9" >
                                <div className="group-8">
                                    <div
                                        onClick={() => handleCategoryChange(1)}
                                        className={`categories ${categoryChosen === 1 && "chosen-category" }`}>
                                        <div className="text-wrapper-4">Football</div>
                                    </div>
                                </div>
                                <div className="group-8">
                                    <div
                                        onClick={() => handleCategoryChange(2)}
                                        className={`categories ${categoryChosen === 2&& "chosen-category" }`}>
                                        <div className="text-wrapper-4">Basketball</div>
                                    </div>
                                </div>
                                <div className="group-8">
                                    <div
                                        onClick={() => handleCategoryChange(3)}
                                        className={`categories ${categoryChosen === 3 && "chosen-category" }`}>
                                        <div className="text-wrapper-4">Rugby</div>
                                    </div>
                                </div>
                                <div className="group-8">
                                    <div
                                        onClick={() => handleCategoryChange(4)}
                                        className={`categories ${categoryChosen === 4 && "chosen-category" }`}>
                                        <div className="text-wrapper-4">Tennis</div>
                                    </div>
                                </div>
                                <div className="group-8">
                                    <div
                                        onClick={() => handleCategoryChange(5)}
                                        className={`categories ${categoryChosen === 5 && "chosen-category" }`}>
                                        <div className="text-wrapper-4">Other</div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="rectangle-10" />*/}
                            <h1 className="h-1">PROSPECT X</h1>

                        </div>
                    </div>
                    <div className="navbar">
                        <div className="search-bar">
                            <div className="overlap-8">
                                <input className="text-wrapper-6" placeholder="Search" onChange={handleSearchChange}></input>
                                <div className="rectangle-11" />
                                <img
                                    className="vector"
                                    alt="Vector"
                                    src="/search-icon.svg"
                                />
                            </div>
                        </div>
                        <img
                            className="notification-icon"
                            alt="Notification icon"
                            src="/notification-icon.svg"
                        />
                        <div className="profile-icon" >
                            <img onClick={() => setShowProfileBox(!showProfileBox)}
                                className="group-13"
                                alt="Group"
                                src="/profile-icon.svg"
                            />
                            <ProfileBox show={showProfileBox} role={userData.role} userId={userData.id} userName={userData.name}></ProfileBox>
                        </div>

                        <img
                            className="fav-icon"
                            alt="Fav icon"
                            src="/fav-icon.svg"
                        />
                        {userData.role === Role.Sportsmen  &&<div className="upload-button">
                            <div className="overlap-10" onClick={() => setShowPopup(true)}>
                                <div className="group-17">
                                    <div className="overlap-upload">
                                        <div className="text-wrapper-7">upload</div>
                                        <div className="text-wrapper-8">+</div>
                                    </div>
                                </div>
                                <img
                                    className="vector-2"
                                    alt="Vector"
                                    src="/icon-cloud.svg"
                                />
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};
