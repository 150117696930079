/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { StyleRound } from "../../icons/StyleRound";
import { StyleSharp } from "../../icons/StyleSharp";
import { StyleTwoTone } from "../../icons/StyleTwoTone";
import "./style.css";

interface Props {
  style: "round" | "sharp" | "filled" | "two-tone" | "outlined";
}

export const Error = ({ style }: Props): JSX.Element => {
  return (
    <>
      {["filled", "outlined", "sharp"].includes(style) && <StyleSharp className="instance-node" />}

      {style === "round" && <StyleRound className="instance-node" />}

      {style === "two-tone" && <StyleTwoTone className="instance-node" />}
    </>
  );
};

Error.propTypes = {
  style: PropTypes.oneOf(["round", "sharp", "filled", "two-tone", "outlined"]),
};
