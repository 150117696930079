/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { IconGoogleOriginal } from "../IconGoogleOriginal";
import "./style.css";

interface Props {
  icon: boolean;
  property1: "primary" | "secondary";
  className: any;
  text: string;
  iconGoogleOriginalIconGoogleOriginal: string;
  onClick: any;
}

export const Buttons = ({
  icon = true,
  property1,
  className,
  text = "Login now",
  iconGoogleOriginalIconGoogleOriginal = "/img/icon-google-original.svg",
  onClick
}: Props): JSX.Element => {
  return (
    <div onClick={() => onClick()} className={`buttons ${property1} ${className}`}>
      {property1 === "secondary" && (
        <IconGoogleOriginal
          className="icon-google-original-instance"
          iconGoogleOriginal={iconGoogleOriginalIconGoogleOriginal}
         icon/>
      )}

      <div className="login-now">{text}</div>
    </div>
  );
};

Buttons.propTypes = {
  icon: PropTypes.bool,
  property1: PropTypes.oneOf(["primary", "secondary"]),
  text: PropTypes.string,
  iconGoogleOriginalIconGoogleOriginal: PropTypes.string,
};
