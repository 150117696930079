import React from 'react';

const UnsopportedDevice: React.FC = () => {
    return (
        <div>
            <h1>Mobile Not Supported</h1>
            <p>Please open this application from a web browser to continue.</p>
        </div>
    );
};

export default UnsopportedDevice;
