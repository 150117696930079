/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  icon: boolean;
  className: any;
  iconGoogleOriginal: string;
}

export const IconGoogleOriginal = ({
  icon = true,
  className,
  iconGoogleOriginal = "/img/icon-google-original-1.png",
}: Props): JSX.Element => {
  return <img className={`icon-google-original ${className}`} alt="Icon google original" src={iconGoogleOriginal} />;
};

IconGoogleOriginal.propTypes = {
  icon: PropTypes.bool,
  iconGoogleOriginal: PropTypes.string,
};
