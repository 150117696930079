import React, {useEffect, useState} from 'react';
import './App.css';
import {SignUp} from "./screens/SignUp";
import {HomePage} from "./screens/HomePage";
import AuthService from "./services/auth-service";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {DetailedPage} from "./screens/DetailedPage";
import UnsopportedDevice from "./components/UnsopportedDevice";
function App() {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };

    checkMobile();
    const user = AuthService.getCurrentUser();
    console.log(user);
    console.log('currentUser');
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  if (isMobile) {
    return <UnsopportedDevice />;
  }

  // return (<div>{!currentUser ? <SignUp/> : <HomePage/> }</div>);
  return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<SignUp/>} />
            <Route path="/home" element={<HomePage/>} />
            <Route path="/profile/:id" element={<DetailedPage/>} />

          </Routes>
        </BrowserRouter>)
}

export default App;
