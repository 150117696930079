/*
We're constantly improving the code you see.
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import {Link} from "react-router-dom";

interface Props {
    icon: boolean;
    property1: "primary" | "secondary";
    className: any;
    text: string;
    iconGoogleOriginalIconGoogleOriginal: string;
    onClick: any;
    item: any;
    isLiked: boolean;
    showClick: boolean;
    isSaved: boolean;
    onSaveClick: any;
}

export const Card = ({
                            icon = true,
    item,
                            property1,
                            className,
                            text = "Login now",
                            iconGoogleOriginalIconGoogleOriginal = "/img/icon-google-original.svg",
                            onClick,
                            isLiked = false,
    isSaved = false,
                            onSaveClick,
    showClick =false,

                        }: Props): JSX.Element => {
    const [isLikedState , setIsLikedState] = React.useState(isLiked);
    return (
        <div className="video-post" key={item.id}>
            <div className="overlap-group">
                <div className="text" />
                <p className="austin-reaves-texas">
                    <Link to={`/profile/${item.user.id}`} className="pp" style={{textDecoration: 'none', color: "inherit"}} ><span className="text-wrapper">{item.user.email}: </span></Link>
                    <span className="span " >{item.text} </span>
                </p>
                <p className="liked-by">
                    <span className="text-wrapper-2">Liked by:   </span>
                    <span className="text-wrapper-3">{item.likedCount | 0 } people </span>
                </p>
            </div>
            <div className="div">
                {/*<img*/}
                {/*    className="photo"*/}
                {/*    alt="Photo"*/}
                {/*    src="/logo512.png"*/}
                {/*/>*/}
                <video width="350em" height="200em" controls >
                    <source src={item.videoUrl} type="video/mp4"/>
                </video>
                <Link to={`/profile/${item.user.id}`} className="pp" style={{textDecoration: 'none'}} ><div className="austin-reaves">{item.user.email}</div></Link>
                {/*<div className="austin-reaves">{item.user.email}</div>*/}
                <div className="group">
                    <div className="overlap-group-2">
                        <div className="group-wrapper">
                            <img
                                className="img"
                                alt="Group"
                                src="/logo512.png"
                            />
                        </div>
                        <div className="rectangle-4" />
                    </div>
                </div>
                {showClick && <div className={'actions-card-container'}>
                    <img
                    className="img-like"
                    alt="Group"
                    src={isLiked ? "./full-like.svg" : "/empty-like.svg"}
                    onClick={onClick}
                    />
                    <img
                        className="img-save"
                        alt="Group"
                        src={isSaved ? "./video-save-full.svg" : "/video-save-empty.svg"}
                        onClick={onSaveClick}
                    />
                </div>}
            </div>
        </div>
    );
};

Card.propTypes = {
    icon: PropTypes.bool,
    property1: PropTypes.oneOf(["primary", "secondary"]),
    text: PropTypes.string,
    iconGoogleOriginalIconGoogleOriginal: PropTypes.string,
    item: PropTypes.string,
    isLiked: PropTypes.bool,
    showClick: PropTypes.bool,
};
