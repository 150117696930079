/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  className: any;
  iconEye: string;
}

export const IconEye = ({ className, iconEye = "/img/icon-eye.svg" }: Props): JSX.Element => {
  return <img className={`icon-eye ${className}`} alt="Icon eye" src={iconEye} />;
};

IconEye.propTypes = {
  iconEye: PropTypes.string,
};
