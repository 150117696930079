import React, {useEffect, useState} from "react";
import "./style.css";
import {Link, Navigate, useLocation, useParams} from "react-router-dom";
import {ProfileBox} from "../../components/ProfileBox";
import {Role, User} from "../../services/interfaces/user.interace";
import AuthService from "../../services/auth-service";
import {Card} from "../../components/Card";
import Upload from "../../components/Upload/Upload";
export const DetailedPage = (): JSX.Element => {
    const  {id } = useParams();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const videoType = params.get('videoType') ;
    const [data, setData] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [showProfileBox, setShowProfileBox] = useState(false);
    const [coverPhoto, setCoverPhoto] = useState<File | null>(null);
    const [isCoverOpen, setIsCoverOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [userDataEdit , setUserDataEdit] = useState({} as User);
    const [isOwnPage, setIsOwnPage] = useState(false);
    let [loggedInUser, setLoggedInUser] = useState({} as User);

    const currentId: string = id || '';
    const userCache = AuthService.getCurrentUser();
    const [userData , setUserData] = useState({} as User);
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        console.log('movedi');
        console.log(videoType);
        const load = async () => {
            if(userCache){
                console.log(2);
                console.log(userCache);
                console.log(1);

                const logedInUser = await AuthService.getCurrentUserData();
                setLoggedInUser(logedInUser);
                if(logedInUser.id === currentId){
                    setIsOwnPage(true);
                }
                const res = await AuthService.getOtherUserInfo(currentId);
                setUserData(res)
                setLoading(false)
                setUserDataEdit(res);
                console.log(1);
            }else{
                console.log(123)
            }
        }
        load();
    }, [id,videoType]);

    useEffect(() => {
        dataFetch(currentId);
    }, [userData,videoType]);

    const dataFetch = async (userId:string) => {
        // sleep for 50 ms
        let data: any = [];
        if(isOwnPage && userData.role === Role.Scout){
            if(videoType === 'liked'){
                data = await AuthService.getLikedVideos(userId);
            }else {
                data = await AuthService.getSavedVideos(userId);
            }
        }else {
            if( isOwnPage && videoType === 'liked'){
                data = await AuthService.getLikedVideos(userId);
            }else if(isOwnPage && videoType === 'saved'){
                data = await AuthService.getSavedVideos(userId);
            }else{
                data = await AuthService.getVideosDetailed(userId);
            }
        }

        // set state when the data received
        setData(data);
    };
    const handleEditChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> , key:string) => {
        setUserDataEdit({...userDataEdit , [key]: event.target.value});
    }
    const handleEditChangeInt = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> , key:string) => {
        setUserDataEdit({...userDataEdit , [key]: event.target.value.length > 0 ? +event.target.value : null});
    }
    if(!userCache){
        return (<Navigate to={'/'}/>)
    }

    const handleCoverSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(!isOwnPage)return;

        // Here you can handle the form submission, e.g., upload the video and title to a server.
        // For this example, we'll simply log the video file and title.
        await AuthService.uploadCoverToNestJS(coverPhoto);
        // Reset form fields
        setCoverPhoto(null);
        setIsCoverOpen(false);
    };
    const handleCoverChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setCoverPhoto(file);
        }
    };

    const handleEditClick = () => {
        if(isEditMode) {
            AuthService.updateUserInfo(userDataEdit).then((res) => {
                AuthService.getUserInfo().then((res) => {
                    setUserData(res);
                    setLoading(false);
                    setUserDataEdit(res);
                    setIsEditMode(false);
                });
            });
        }else{
            setIsEditMode(true);
        }
    }
    if(isLoading){
        return (<div>Loading...</div>)
    }
    return (
        <div className="sportsman-page-ver">
            <div className="overlap-wrapper">
                { showPopup && <Upload onClose={() => setShowPopup(false)}></Upload>}
                {isCoverOpen && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <button className="popup-close-button" onClick={() => setIsCoverOpen(false)}>
                                X
                            </button>
                            <form onSubmit={handleCoverSubmit}>
                                <input type="file" accept="image/png, image/gif, image/jpeg" onChange={handleCoverChange} />
                                <button type="submit">Set Cover Photo</button>
                            </form>
                        </div>
                    </div>
                )}
                <div className="overlap">
                    <div className="side-bar">
                        <h1 className="text-wrapper"><Link to={'/home'} style={{textDecoration: 'none'}} className="text-wrapper11" >PROSPECT X</Link></h1>
                    </div>
                    <div className="navbar">
                        <img
                            className="notification-icon"
                            alt="Notification icon"
                            src="/notification-icon.svg"
                        />
                        <div className="profile-icon" >
                            <img onClick={() => setShowProfileBox(!showProfileBox)}
                                 className="group-13"
                                 alt="Group"
                                 src="/profile-icon.svg"
                            />
                            <ProfileBox show={showProfileBox} userId={loggedInUser.id} role={loggedInUser.role} userName={loggedInUser.name}></ProfileBox>
                        </div>

                        <img
                            className="fav-icon"
                            alt="Fav icon"
                            src="/fav-icon.svg"
                        />
                        {userData.role === Role.Sportsmen && <div className="upload-button">
                            <div className="overlap-10" onClick={() => setShowPopup(true)}>
                                <div className="group-17">
                                    <div className="overlap-upload">
                                        <div className="text-wrapper-7">upload</div>
                                        <div className="text-wrapper-8">+</div>
                                    </div>
                                </div>
                                <img
                                    className="vector-2"
                                    alt="Vector"
                                    src="/icon-cloud.svg"
                                />
                            </div>
                        </div>}
                    </div>
                    <div className="overlap-group-sp">
                        <div className="overlap-4">
                            <div className={'cover-photo'}>
                                <img
                                className="guillaume-de-germain"
                                alt="Cover photo"
                                src={userData.profileCover ? userData.profileCover : "/download.jpeg"}
                                />
                                { isOwnPage && <img
                                    className="cover-upload"
                                    onClick={() => setIsCoverOpen(true)}
                                    alt="Cover upload"
                                    src="/upload-sign-svgrepo-com.svg"
                                />}

                            </div>

                            <div className="search-hover-main">
                                <div className="overlap-5">
                                    <div className="overlap-6">
                                        <div className="born">
                      <span className="text-wrapper-5">
                        Born
                        <br />
                      </span>
                                            <span className="text-wrapper-6">
                      {isEditMode ? <input type={'number'}
                          onChange={(event) => handleEditChangeInt(event , 'birthDate')}
                          className={'input-text'} value={userDataEdit.birthDate}></input> : userData.birthDate}
                        <br />
                      </span>
                                        </div>
                                        <div className="height">
                      <span className="text-wrapper-5">
                        Height
                        <br />
                      </span>
                                            <span className="text-wrapper-6">
                        {isEditMode ? <input type={'number'}
                            onChange={(event) => handleEditChangeInt(event , 'height')}
                            className={'input-text'} value={userDataEdit.height}></input> : userData.height}
                        <br />
                      </span>
                                        </div>
                                        <div className="weight">
                      <span className="text-wrapper-5">
                        Weight
                        <br />
                      </span>
                                            <span className="text-wrapper-6">
                        {isEditMode ? <input type={'number'}
                            onChange={(event) => handleEditChangeInt(event , 'weight')}
                            className={'input-text'} value={userDataEdit.weight} ></input> : userData.weight}
                        <br />
                      </span>
                                        </div>
                                        {isEditMode ? <textarea
                                            onChange={(event) => handleEditChange(event , 'description')}
                                            rows={5} className={'input-text'} value={userDataEdit.description}></textarea> : (<p className="bio-lorem-ipsum-is">
                                            {userData.description}
                                        </p>)}
                                        <img
                                            className="line"
                                            alt="Line"
                                            src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-33-1.svg"
                                        />
                                    </div>
                                    <div className="current-team-inter">
                    <span className="text-wrapper-5">
                      Current Team:
                      <br />
                    </span>
                                        <span className="text-wrapper-6">
                      {isEditMode ? <input
                          onChange={(event) => handleEditChange(event , 'currentTeam')}
                          className={'input-text'} value={userDataEdit.currentTeam}></input> : userData.currentTeam}
                      <br />
                    </span>
                                    </div>
                                    <div className="leagues">
                    <span className="text-wrapper-5">
                      Leagues:
                      <br />
                    </span>
                                        <span className="text-wrapper-6">
                      {isEditMode ? <input
                          onChange={(event) => handleEditChange(event , 'leagues')}
                          className={'input-text'} value={userDataEdit.leagues}></input> : userData.leagues}
                      <br />
                    </span>
                                    </div>
                                    <div className="supercup">
                    <span className="text-wrapper-5">
                      Supercup:
                      <br />
                    </span>
                                        <span className="text-wrapper-6">
                      {isEditMode ? <input
                          onChange={(event) => handleEditChange(event , 'supercup')}
                          className={'input-text'} value={userDataEdit.supercup}></input> : userData.supercup}
                      <br />
                    </span>
                                    </div>
                                    {/*<div className="text-wrapper-7">Top Skills:</div>*/}
                                    <div className="text-wrapper-88">{userData.name}</div>
                                    <img
                                        className="line-2"
                                        alt="Line"
                                        src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-33-1.svg"
                                    />
                                    <img
                                        className="line-3"
                                        alt="Line"
                                        src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-33-1.svg"
                                    />
                                    <img
                                        className="line-4"
                                        alt="Line"
                                        src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-33-1.svg"
                                    />
                                    <img
                                        className="line-5"
                                        alt="Line"
                                        src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-33-1.svg"
                                    />
                                    {/*<div className="group-4">*/}
                                    {/*    <div className="text-wrapper-9">Scoring</div>*/}
                                    {/*    <div className="overlap-group-3">*/}
                                    {/*        <img*/}
                                    {/*            className="line-6"*/}
                                    {/*            alt="Line"*/}
                                    {/*            src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-35-1.svg"*/}
                                    {/*        />*/}
                                    {/*        <img*/}
                                    {/*            className="line-7"*/}
                                    {/*            alt="Line"*/}
                                    {/*            src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-36-1.svg"*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="group-5">*/}
                                    {/*    <div className="text-wrapper-9">Freekicks</div>*/}
                                    {/*    <div className="overlap-group-3">*/}
                                    {/*        <img*/}
                                    {/*            className="line-6"*/}
                                    {/*            alt="Line"*/}
                                    {/*            src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-35-1.svg"*/}
                                    {/*        />*/}
                                    {/*        <img*/}
                                    {/*            className="line-7"*/}
                                    {/*            alt="Line"*/}
                                    {/*            src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-36-1.svg"*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    { isOwnPage && <div className="group-6">
                                        <div className="frame">
                                            <div className="text-wrapper-10 edit-profile"
                                                 onClick={() => handleEditClick()}>{isEditMode ? "Save" : "Edit Profile"}</div>
                                        </div>
                                        <img
                                            className="line-8"
                                            alt="Line"
                                            src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-38.svg"
                                        />
                                    </div>}
                                </div>
                            </div>
                            <div className="top-star-icon">
                                <img
                                    className="star"
                                    alt="Star"
                                    src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/star-1.svg"
                                />
                            </div>
                            <ul className="main-space">
                                {(userData.role === Role.Scout || videoType) && <div className={'title-saved'}>
                                    <h1>{ videoType === "liked" ? "Liked videos" : "Saved videos"}</h1>
                                    <img
                                        className="line-saved"
                                        alt="Line"
                                        src="https://generation-sessions.s3.amazonaws.com/2e4790a5cbd3e92280ee18712551c762/img/line-33-1.svg"
                                    />
                                </div>}
                                <br/>
                                {
                                    data.map((videoData: any) => {
                                        return (<li key={videoData.id}>
                                                <Card isSaved={false} onSaveClick={() => console.log(1)} showClick={false}  item={videoData} text={videoData.title}  isLiked={videoData.isLiked} className={"video-post"} icon={false} iconGoogleOriginalIconGoogleOriginal={""} onClick={null} property1={"primary"}></Card>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
