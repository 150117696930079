import React, { useState } from 'react';
import './style.css';
import AuthService from "../../services/auth-service";
import PropTypes from "prop-types"; // You can create the CSS file to style the popup and overlay

interface Props {
    onClose: any;
}
const Upload = ({
                              onClose
                             }: Props): JSX.Element => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [videoTitle, setVideoTitle] = useState<string>('');
    const [percentage, setPercentage] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const handlePopupOpen = () => {
        setIsOpen(true);
    };

    const handlePopupClose = () => {
        setIsOpen(false);
        onClose();
    };

    const handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setVideoFile(file);
        }
    };

    const handleVideoTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVideoTitle(event.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // Here you can handle the form submission, e.g., upload the video and title to a server.
        // For this example, we'll simply log the video file and title.
        console.log('Uploaded Video:', videoFile);
        console.log('Video Title:', videoTitle);
        setIsUploading(true);
        await AuthService.uploadFileToNestJS(videoFile , videoTitle, setPercentage);
        setIsUploading(false);
        // Reset form fields
        setVideoFile(null);
        setVideoTitle('');

        // Close the popup
        setIsOpen(false);
    };

    return (
        <div>
            {isOpen && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <button className="popup-close-button" onClick={handlePopupClose}>
                            X
                        </button>
                        <form onSubmit={handleSubmit}>
                            <input type="file" accept="video/mp4,video/mkv, video/x-m4v,video/*" onChange={handleVideoChange} />
                            <input
                                type="text"
                                placeholder="Enter video title..."
                                value={videoTitle}
                                onChange={handleVideoTitleChange}
                            />
                            <button type="submit">{isUploading ? "Uploading..." : "Upload Video"}</button>
                            {isUploading && <progress value={percentage} max="100" />}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};
Upload.propTypes = {
    onClose: PropTypes.func.isRequired
};

export default Upload;
